<template>
<div>
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    Bank Transactions
                </h3>
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="searchString"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper">
                    <b-button
                        v-b-modal.add-transaction
                        class="btn btn-label-success btn-bold"
                    >
                        <i class="flaticon2-user" />
                        Add Transaction
                    </b-button>

                    <b-modal
                        id="add-transaction"
                        centered
                        no-close-on-backdrop
                        @ok="addTransaction"
                    >
                        <template #modal-header>
                            <h5>Add New Transaction</h5>
                        </template>

                        <div class="form-group validated">
                            <label>Transaction Id</label>
                            <input
                                v-model="extTransactionId"
                                type="text"
                                class="form-control"
                                placeholder="ORIG CO NAME:THE CITY OF NEW ORIG ID:7136400434 DESC DATE:221212 CO...."
                                :class="!transactionError ? '' : 'is-invalid'"
                            >

                            <div
                                v-if="extTransactionId && transactionError"
                                class="form-text invalid-feedback"
                            >
                                {{ transactionError }}
                            </div>
                            <span
                                v-else
                                class="form-text text-muted"
                            >
                                The transcation id is the description of the deposit
                            </span>
                        </div>
                        <template v-if="!transactionError">
                            <div class="form-group validated">
                                <label>Transaction Amount</label>
                                <div class="input-group">
                                    <input
                                        v-model="transactionAmount"
                                        type="number"
                                        :class="transactionAmount ? '' : 'is-invalid'"
                                        class="form-control"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ formattedTransactionAmount }}</span>
                                    </div>
                                </div>
                                <div
                                    v-if="!transactionAmount"
                                    class="form-text invalid-feedback"
                                >
                                    Please enter an amount.
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Transaction Date</label>
                                <input
                                    :value="transactionDate"
                                    type="text"
                                    :disabled="true"
                                    class="form-control"
                                >
                            </div>
                            <div class="form-group">
                                <label>Check / ETF Number</label>
                                <input
                                    :value="transactionCheckNumber"
                                    type="text"
                                    :disabled="true"
                                    class="form-control"
                                >
                            </div>
                        </template>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="row">
                    <div
                        v-for="item in transactionTotals"
                        :key="`year_${item.calendarYear}`"
                        class="col-xl-3 col-lg-3 col-md-4 col-sm-6"
                    >
                        <div class="kt-widget12">
                            <div class="kt-widget12__content">
                                <div class="kt-widget12__item">
                                    <div class="kt-widget12__info">
                                        <span class="kt-widget12__desc">{{ item.calendarYear }} Total</span>
                                        <span class="kt-widget12__value">{{ formatCurrency(item.total) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Check / ETF Number
                                </th>
                                <th>
                                    Amount
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Agreements
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in transactions"
                                :key="`trans_${item.transactionCheckNumber}`"
                            >
                                <td>
                                    {{ item.transactionDate.substr(0, 10) }}
                                </td>
                                <td>
                                    <router-link
                                        :to="{ name: 'TransactionDetails', params: { transactionCheckNumber: item.transactionCheckNumber } }"
                                        class="kt-font-bold"
                                        style="min-width: 200px"
                                    >
                                        {{ item.transactionCheckNumber }}
                                    </router-link>
                                </td>
                                <td>
                                    {{ formatCurrency(item.transactionAmount) }}
                                </td>
                                <td>
                                    N/A
                                </td>
                                <td :class="{'kt-font-danger': item.agreements.length == 0}">
                                    {{ item.agreements.length }} agreements
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import moment from 'moment';
import numeral from 'numeral';
import Types from '../../../store/Types';
import * as network from '../../../network';

export default {
    name: 'Transactions',
    data() {
        return {
            searchString: '',
            extTransactionId: '',
            transactionAmount: 0,
        };
    },
    computed: {
        ...mapState({
            transactions: (state) => state.database.transactions,
            schools: (state) => state.database.schools,
            deviceType: (state) => state.deviceType,
        }),
        transactionTotals() {
            const { transactions } = this;
            const transactionYears = transactions.map((a) => a.transactionDate.substr(0, 4));
            return [...new Set(transactionYears)].map((year) => ({
                calendarYear: year,
                total: transactions
                    .filter((a) => a.transactionDate.startsWith(year))
                    .map((a) => a.transactionAmount)
                    .reduce((a, b) => a + b),
            }));
        },
        transactionError() {
            if (this.transactionFormatError) return this.transactionFormatError;
            return null;
        },
        transactionFormatError() {
            const extTransactionId = (this.extTransactionId || '').trim();
            if (!extTransactionId.startsWith('ORIG CO NAME:THE CITY OF NEW ORIG ID:7136400434')) {
                return 'Missing "THE CITY OF NEW" prefix';
            }
            const { transactionDate } = this;
            if (!transactionDate) {
                return 'Missing Date Identifier';
            }
            const { transactionCheckNumber } = this;
            if (!transactionCheckNumber) {
                return 'Missing EFT Check Number';
            }

            return null;
        },
        transactionCheckNumber() {
            const extTransactionId = (this.extTransactionId || '').trim();
            if (!extTransactionId.includes('IND ID:')) return null;
            const check = extTransactionId.split('IND ID:')[1];
            const output = check.split(' ')[0].trim();
            const passedTest = new RegExp(/^\d+$/);
            if (!passedTest) return null;
            return output;
        },
        transactionDate() {
            const extTransactionId = (this.extTransactionId || '').trim();
            if (!extTransactionId.includes('EED:')) return null;
            const date = extTransactionId.split('EED:')[1].substr(0, 6);
            return moment(date, 'YYMMDD').format('YYYY-MM-DD');
        },
        formattedTransactionAmount() {
            return numeral(this.transactionAmount).format('$ 0,0[.]00');
        },
        transaction() {
            const {
                extTransactionId,
                transactionDate,
                transactionAmount,
                transactionCheckNumber,
            } = this;

            if (!transactionAmount
                || !extTransactionId
                || !transactionDate
                || !transactionCheckNumber) { return null; }

            return {
                transactionCheckNumber,
                transactionDate,
                transactionAmount,
                transactionDescription: extTransactionId,
                schoolSubscriptionId: null,
                deleted: false,
            };
        },
    },
    mounted() {
        if (this.schools.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_SCHOOLS);
        }
        if (this.transactions.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_TRANSACTIONS);
        }
    },
    methods: {
        addTransaction() {
            const v = this;
            const params = {
                body: { transaction: this.transaction },
            };

            if (this.transactionError) {
                return v.showError(v.transactionError);
            }

            const { transactionAmount } = this;
            if (!transactionAmount) {
                return v.showError('Missing Amount');
            }

            network.admin.upsertTransaction(params, (err) => {
                if (err) v.showError(err);
                this.$store.dispatch(Types.actions.REFRESH_ADMIN_TRANSACTIONS);
            });
        },
    },
};
</script>

<style scoped>
tbody tr td {

    vertical-align: middle;
}
</style>
