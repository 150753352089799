var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "kt-subheader kt-grid__item",
        attrs: { id: "kt_subheader" },
      },
      [
        _c("div", { staticClass: "kt-container kt-container--fluid" }, [
          _c("div", { staticClass: "kt-subheader__main" }, [
            _c("h3", { staticClass: "kt-subheader__title" }, [
              _vm._v(" Bank Transactions "),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "kt-input-icon kt-input-icon--right kt-subheader__search mt-1",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchString,
                      expression: "searchString",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Filter..." },
                  domProps: { value: _vm.searchString },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchString = $event.target.value
                    },
                  },
                }),
                _vm._m(0),
              ]
            ),
          ]),
          _c("div", { staticClass: "kt-subheader__toolbar" }, [
            _c(
              "div",
              { staticClass: "kt-subheader__wrapper" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.add-transaction",
                        modifiers: { "add-transaction": true },
                      },
                    ],
                    staticClass: "btn btn-label-success btn-bold",
                  },
                  [
                    _c("i", { staticClass: "flaticon2-user" }),
                    _vm._v(" Add Transaction "),
                  ]
                ),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "add-transaction",
                      centered: "",
                      "no-close-on-backdrop": "",
                    },
                    on: { ok: _vm.addTransaction },
                    scopedSlots: _vm._u([
                      {
                        key: "modal-header",
                        fn: function () {
                          return [_c("h5", [_vm._v("Add New Transaction")])]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("div", { staticClass: "form-group validated" }, [
                      _c("label", [_vm._v("Transaction Id")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.extTransactionId,
                            expression: "extTransactionId",
                          },
                        ],
                        staticClass: "form-control",
                        class: !_vm.transactionError ? "" : "is-invalid",
                        attrs: {
                          type: "text",
                          placeholder:
                            "ORIG CO NAME:THE CITY OF NEW ORIG ID:7136400434 DESC DATE:221212 CO....",
                        },
                        domProps: { value: _vm.extTransactionId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.extTransactionId = $event.target.value
                          },
                        },
                      }),
                      _vm.extTransactionId && _vm.transactionError
                        ? _c(
                            "div",
                            { staticClass: "form-text invalid-feedback" },
                            [_vm._v(" " + _vm._s(_vm.transactionError) + " ")]
                          )
                        : _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              " The transcation id is the description of the deposit "
                            ),
                          ]),
                    ]),
                    !_vm.transactionError
                      ? [
                          _c("div", { staticClass: "form-group validated" }, [
                            _c("label", [_vm._v("Transaction Amount")]),
                            _c("div", { staticClass: "input-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.transactionAmount,
                                    expression: "transactionAmount",
                                  },
                                ],
                                staticClass: "form-control",
                                class: _vm.transactionAmount
                                  ? ""
                                  : "is-invalid",
                                attrs: { type: "number" },
                                domProps: { value: _vm.transactionAmount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.transactionAmount = $event.target.value
                                  },
                                },
                              }),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formattedTransactionAmount)
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            !_vm.transactionAmount
                              ? _c(
                                  "div",
                                  { staticClass: "form-text invalid-feedback" },
                                  [_vm._v(" Please enter an amount. ")]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Transaction Date")]),
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { type: "text", disabled: true },
                              domProps: { value: _vm.transactionDate },
                            }),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Check / ETF Number")]),
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { type: "text", disabled: true },
                              domProps: { value: _vm.transactionCheckNumber },
                            }),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.transactionTotals, function (item) {
                return _c(
                  "div",
                  {
                    key: `year_${item.calendarYear}`,
                    staticClass: "col-xl-3 col-lg-3 col-md-4 col-sm-6",
                  },
                  [
                    _c("div", { staticClass: "kt-widget12" }, [
                      _c("div", { staticClass: "kt-widget12__content" }, [
                        _c("div", { staticClass: "kt-widget12__item" }, [
                          _c("div", { staticClass: "kt-widget12__info" }, [
                            _c("span", { staticClass: "kt-widget12__desc" }, [
                              _vm._v(_vm._s(item.calendarYear) + " Total"),
                            ]),
                            _c("span", { staticClass: "kt-widget12__value" }, [
                              _vm._v(_vm._s(_vm.formatCurrency(item.total))),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-striped table-hover" }, [
                _vm._m(1),
                _c(
                  "tbody",
                  _vm._l(_vm.transactions, function (item) {
                    return _c(
                      "tr",
                      { key: `trans_${item.transactionCheckNumber}` },
                      [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(item.transactionDate.substr(0, 10)) +
                              " "
                          ),
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "kt-font-bold",
                                staticStyle: { "min-width": "200px" },
                                attrs: {
                                  to: {
                                    name: "TransactionDetails",
                                    params: {
                                      transactionCheckNumber:
                                        item.transactionCheckNumber,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.transactionCheckNumber) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatCurrency(item.transactionAmount)
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [_vm._v(" N/A ")]),
                        _c(
                          "td",
                          {
                            class: {
                              "kt-font-danger": item.agreements.length == 0,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.agreements.length) +
                                " agreements "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "flaticon2-search-1" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" Date ")]),
        _c("th", [_vm._v(" Check / ETF Number ")]),
        _c("th", [_vm._v(" Amount ")]),
        _c("th", [_vm._v(" Status ")]),
        _c("th", [_vm._v(" Agreements ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }